import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
    square: {
        display: 'flex',
        height: 200,
        padding: 10,
        flexBasis: '50%',
        justifyContent: 'center',
        flexDirection: 'column',
        color: 'white',
    },
    rightUpper: {
        backgroundColor: theme.palette.matrixChart.primary.main,
        filter: 'brightness(1.4)',
    },
    leftUpper: {
        backgroundColor: theme.palette.matrixChart.primary.main,
        filter: 'brightness(1.6)',
    },
    rightLower: {
        backgroundColor: theme.palette.matrixChart.primary.main,
        filter: 'brightness(1.8)',
    },
    leftLower: {
        backgroundColor: theme.palette.matrixChart.primary.main,
        filter: 'brightness(2.0)',
    },
    label: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'row',
        fontSize: 14,
        [theme.breakpoints.up('sm')]: {
            fontSize: 18
        },
    },
    labelMore: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'row',
        fontSize: 12,
        [theme.breakpoints.up('sm')]: {
            fontSize: 14
        },
        paddingTop: 16,
        cursor: 'pointer',
    },
}));
