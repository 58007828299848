import React, { FC } from "react";
import { Radar } from 'react-chartjs-2';
import { theme } from "../theme/theme";
import { formatDecimalValue, formatDecimalValueWithFallback, labelIdentifiers } from "../util/chart-util";
import { RadarChartEntry } from "../customTypes";
import { useStyles } from "../radar-chart/RadarChart.style";

interface RadarChartProps {
    labels: string[];
    entries: RadarChartEntry[]
}

export const RadarChart: FC<RadarChartProps> = (props: RadarChartProps) => {
    const dataLabels: string[] = labelIdentifiers.slice(0, props.labels.length);
    const styles = useStyles();
    const dataEntries = props.entries.map((e, index) => {
        return {
            label: e.title,
            backgroundColor: theme.palette.chartEntries[index].background.main,
            borderColor: theme.palette.chartEntries[index].border.main,
            pointBackgroundColor: theme.palette.chartEntries[index].point.main,
            pointBorderColor: theme.palette.chartEntries[index].point.main,
            pointHoverBackgroundColor: '#fff',
            pointHoverBorderColor: theme.palette.chartEntries[index].point.main,
            data: e.values
        };
    });

    const data: Chart.ChartData = {
        labels: dataLabels,
        datasets: dataEntries
    };

    const options: Chart.ChartOptions = {
        scale: {
            ticks: {
                min: 0,
                max: 5,
                stepSize: 1
            },
            pointLabels: {
                fontSize: 12
            }
        },
        tooltips: {
            displayColors: false,
            callbacks: {
               title: (item, data) => { return '' },
               label: (tooltipItem, data) => { 
                    return formatDecimalValueWithFallback(tooltipItem.value, 1, '?');
                }
            },
            bodyFontSize: 16,
        },
        elements: {
            point: {
                radius: 6,
            },
        },
        maintainAspectRatio: false,
        responsive: true,
    }

    const labels: React.ReactNode[] = [];
    props.labels.forEach((label, index) => {
        labels.push(
            <li className={styles.li} key={index}>
                {label} ({formatDecimalValue(props.entries.find(e => e.own)!.values[index], 1)})
            </li>
        )
    });

    return (
        <React.Fragment>
            <div className={styles.radarContainer}>
                <Radar data={data} options={options}/>
            </div>
            <div className={styles.labels}>
                <ul className={styles.list}>
                    {labels}
                </ul>
            </div>
        </React.Fragment>
    );
};