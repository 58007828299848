import { createMuiTheme } from '@material-ui/core/styles';
import { themeColours, chartEntries, matrixChart } from './colours';
import { CustomPalette, CustomPaletteOptions } from './themeTypes';
import { PaletteColor, PaletteColorOptions } from '@material-ui/core/styles/createPalette';

declare module '@material-ui/core/styles/createPalette' {
  interface Palette {
    chartEntries: CustomPalette[];
  }

  interface PaletteOptions {
    chartEntries?: CustomPaletteOptions[];
  }

  interface Palette {
    matrixChart: {
      primary: PaletteColor;
      axes: PaletteColor;
    }
  }

  interface PaletteOptions {
    matrixChart?: {
      primary: PaletteColorOptions;
      axes: PaletteColorOptions;
    }
  }
}

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: themeColours.primary
    },
    secondary: {
      main: themeColours.secondary
    },
    matrixChart: {
      primary: {
        main: matrixChart.primary
      },
      axes: {
        main: matrixChart.axes
      },
    },
    chartEntries: chartEntries.map((colour) => {
      return {
        background: {
          main: colour.transparent
        },
        border: {
          main: colour.full
        },
        point: {
          main: colour.full
        },
        marker: {
          main: colour.full
        }
      }
    }),
  }
});
