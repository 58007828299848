import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
   logo: {
       height: 48,
       margin: 10,
   },
   logoContainer: {
       display: 'flex',
       justifyContent: 'center',
   }
}));
