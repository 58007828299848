import { QuestionObject, SurveyObject, CompanyInformation, OptionObject } from "../customTypes";

type SurveyActionType =
  | "FETCH_SURVEY_PENDING"
  | "FETCH_SURVEY_SUCCESS"
  | "FETCH_SURVEY_ERROR"
  | "ANSWER_SELECTED"
  | "ANSWER_DESELECTED"
  | "PAGE_SET"
  | "COMPANY_INFORMATION_SET"
  | "QUESTION_INDEX_SET"
  | "SAVE_COMPANY_INFORMATION_PENDING"
  | "SAVE_COMPANY_INFORMATION_SUCCESS"
  | "SAVE_COMPANY_INFORMATION_ERROR"
  | "SAVE_QUESTION_ANSWER_PENDING"
  | "SAVE_QUESTION_ANSWER_SUCCESS"
  | "SAVE_QUESTION_ANSWER_ERROR";

export interface SurveyAction {
  type: SurveyActionType;
  payload?: any;
}

interface SurveyActions {
  deselectAnswer: (question:QuestionObject, option:OptionObject | undefined) => SurveyAction;
  fetchSurveyPending: () => SurveyAction;
  fetchSurveySuccess: (survey:SurveyObject) => SurveyAction;
  fetchSurveyError: (error:string) => SurveyAction;
  saveCompanyInformationPending: () => SurveyAction;
  saveCompanyInformationSuccess: (sessionUuid:string) => SurveyAction;
  saveCompanyInformationError: (error:string) => SurveyAction;
  saveQuestionAnswerPending: () => SurveyAction;
  saveQuestionAnswerSuccess: (sessionUuid:string) => SurveyAction;
  saveQuestionAnswerError: (error:string) => SurveyAction;
  selectAnswer: (question:QuestionObject, option: OptionObject | undefined) => SurveyAction;
  setCompanyInformation: (companyInformation:CompanyInformation) => SurveyAction;
  setPage: (page: string) => SurveyAction;
  setQuestionIndex: (index: number) => SurveyAction;
}

export const surveyActions: SurveyActions = {
  deselectAnswer: (question:QuestionObject, option: OptionObject | undefined) => {
    return {
      type: "ANSWER_DESELECTED",
      payload: {
        "question": question,
        "option": option,
      }
    };
  },
  fetchSurveyPending: () => {
    return {
      type: "FETCH_SURVEY_PENDING"
    };
  },
  fetchSurveySuccess: (survey:SurveyObject) => {
    return {
      type: "FETCH_SURVEY_SUCCESS",
      payload: survey
    };
  },
  fetchSurveyError: (error:string) => {
    return {
      type: "FETCH_SURVEY_ERROR",
      payload: error
    };
  },
  saveCompanyInformationPending: () => {
    return {
      type: "SAVE_COMPANY_INFORMATION_PENDING"
    };
  },
  saveCompanyInformationSuccess: (sessionUuid:string) => {
    return {
      type: "SAVE_COMPANY_INFORMATION_SUCCESS",
      payload: sessionUuid
    };
  },
  saveCompanyInformationError: (error:string) => {
    return {
      type: "SAVE_COMPANY_INFORMATION_ERROR",
      payload: error
    };
  },
  saveQuestionAnswerPending: () => {
    return {
      type: "SAVE_QUESTION_ANSWER_PENDING"
    };
  },
  saveQuestionAnswerSuccess: (sessionUuid:string) => {
    return {
      type: "SAVE_QUESTION_ANSWER_SUCCESS",
      payload: sessionUuid
    };
  },
  saveQuestionAnswerError: (error:string) => {
    return {
      type: "SAVE_QUESTION_ANSWER_ERROR",
      payload: error
    };
  },
  selectAnswer: (question:QuestionObject, option: OptionObject | undefined) => {
    return {
      type: "ANSWER_SELECTED",
      payload: {
        "question": question,
        "option": option,
      }
    };
  },
  setCompanyInformation: (companyInformation:CompanyInformation) => {
    return {
      type: "COMPANY_INFORMATION_SET",
      payload: companyInformation
    };
  },
  setPage: (page: string) => {
    return {
      type: "PAGE_SET",
      payload: page
    };
  },
  setQuestionIndex: (index: number) => {
    return {
      type: "QUESTION_INDEX_SET",
      payload: index
    };
  }
};
