import React, { FC } from "react";
import { useStyles } from "./MatrixSquare.style";
import { injectIntl, FormattedMessage, InjectedIntlProps } from "react-intl";

interface MatrixSquareProps extends InjectedIntlProps {
    label: string;
    class: string;
    toggleDialog: () => void;
}

export const MatrixSquareWrapped: FC<MatrixSquareProps> = (props: MatrixSquareProps) => {
  const styles = useStyles();
  
  return (
    <React.Fragment>
     <div className={[styles.square, props.class].join(" ")}>
        <div className={styles.label}>
            {props.label}
        </div>
        <div className={styles.labelMore} onClick={() => {
            props.toggleDialog();
        }}>
        <FormattedMessage id="matrix.readMore" />
        </div>
      </div>
    </React.Fragment>
  );
};

export const MatrixSquare = injectIntl(MatrixSquareWrapped);