import { ThemeColours, ChartColour, MatrixColours } from './themeTypes';

export const themeColours: ThemeColours = {
  primary: 'rgba(0, 61, 115, 1)',
  secondary: 'rgba(27, 117, 196, 1)',
};

export const chartEntries: ChartColour[] = [
  {
    full: 'rgba(45, 166, 185, 1)',
    transparent: 'rgba(45, 166, 185, .2)'
  },
  {
    full: 'rgba(220, 100, 20, 1)',
    transparent: 'rgba(220, 100, 20, .2)'
  },
  {
    full: 'rgba(27, 150, 19, 1)',
    transparent: 'rgba(27, 150, 19, .2)'
  },
];

export const matrixChart: MatrixColours = {
  primary: 'rgba(0, 61, 115, 1)',
  axes: 'rgba(102, 102, 102, 1)',
}