import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex'
  },
  appBar: {
    position: 'fixed'
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingRight: 4
  },
  title: {
    fontSize: 16,
    fontWeight: 500,
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      fontSize: 20,
      fontWeight: 400,
    }
  },
  logo: {
    height: 44,
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      height: 56
    }
  },
  layout: {
    width: 'auto',
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(20),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: 'auto',
      marginRight: 'auto',
      marginTop: theme.spacing(12),
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    minHeight: 300,
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  progress: {
    width:"100%",
    paddingTop: theme.spacing(1)
  },
  linearProgress: {
    display: 'none',
    [theme.breakpoints.up(360)]: {
      display: 'block'
    },
  },
  buttonLink: {
    textDecoration: 'none'
  },
  previousButton: {
    float: 'left',
    '@media print': {
      display: 'none'
    },
  },
  nextButton: {
    float: 'right',
    '@media print': {
      display: 'none'
    },
  },
  footer: {
    position: 'fixed',
    left: 0,
    bottom: 0,
    width: '100%',
    backgroundColor: '#ebebeb',
    textAlign: 'center',
    padding: theme.spacing(2)
  }
}));
