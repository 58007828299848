import { ChartValue, SemanticCategory } from "../reducers/chart-reducer";
import { MatrixMarker, MatrixTitles, RadarChartEntry } from "../customTypes";
import { InjectedIntlProps } from "react-intl";

export const xAxisUuid = 'feeb5371-6dad-44a2-8f81-6734abc7ccfe';
export const yAxisUuid = '1cf3533b-f630-45a2-8b96-3cf62b0bac2e';
export const pointsDivider = 42;

export const formatDecimalValue = (num: number, decimals: number): string => {
    return num.toFixed(decimals);
}

export const formatDecimalValueWithFallback = (num: string | undefined, decimals: number, fallback: string): string => {
    return num === undefined ? fallback : formatDecimalValue(parseFloat(num), decimals); 
}

export const labelIdentifiers = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];

export const createLabelsWithIdentifiers = (labels: string[], identifiers: string[]): string[] => {
    return labels.map((label, index) => { return identifiers[index] + ': ' + label })
}

export const createEntries = (props: InjectedIntlProps, values: number[], averagesAll: number[], averagesIndustry: number[]): RadarChartEntry[] => {
  const entries: RadarChartEntry[] = [
    {
      title: props.intl.formatMessage({ id: "results.yourCompany" }),
      values,
      own: true,
    },
    {
      title: props.intl.formatMessage({ id: "results.averageAll" }),
      values: averagesAll,
    },
  ];
  const isIndustryChosen = averagesIndustry.some((value) => value > 0);
  if (isIndustryChosen) {
    entries.push({
      title: props.intl.formatMessage({ id: "results.averageIndustry" }),
      values: averagesIndustry,
    });
  }
  return entries;
}

export const calcRadarValue = (value: number, max: number): number => {
    const maxChartValue = 5;
    return parseFloat(formatDecimalValue((value / max) * maxChartValue, 1));
}

export const calcMatrixValue = (value: number, max: number): number => {
    return parseFloat(((value/max) * 100).toFixed(0));
}

export const formatMultipleTexts = (content: string): string[] => {
    return content.split('$');
}

export const getSemanticCategory = (chartValues: ChartValue[]): SemanticCategory => {
    const chartValueX: ChartValue | undefined = chartValues.find(item => item.uuid === xAxisUuid);
    const chartValueY: ChartValue | undefined = chartValues.find(item => item.uuid === yAxisUuid);

    const pointsX = chartValueX === undefined ? 0 : chartValueX.value;
    const pointsY = chartValueY === undefined ? 0 : chartValueY.value;

    let semanticCategory: SemanticCategory = SemanticCategory.LEFT_LOWER; 

    if (pointsX < pointsDivider && pointsY < pointsDivider) {
      semanticCategory = SemanticCategory.LEFT_LOWER;
    } else if (pointsX < pointsDivider && pointsY >= pointsDivider) {
      semanticCategory = SemanticCategory.LEFT_UPPER;
    } else if (pointsX >= pointsDivider && pointsY < pointsDivider) {
      semanticCategory = SemanticCategory.RIGHT_LOWER;
    } else if (pointsX >= pointsDivider && pointsY >= pointsDivider) {
      semanticCategory = SemanticCategory.RIGHT_UPPER;
    }

    return semanticCategory;
};

export const getMatrixChartX = (chartValues: ChartValue[]): ChartValue | undefined => {
  return chartValues.find(item => item.uuid === xAxisUuid);
}

export const getMatrixChartY = (chartValues: ChartValue[]): ChartValue | undefined => {
  return chartValues.find(item => item.uuid === yAxisUuid);
}

export const getMatrixMarkers = (chartX: ChartValue | undefined, chartY: ChartValue | undefined, titles: MatrixTitles): MatrixMarker[] => {
    const markers: MatrixMarker[] = [
        {
          title: titles.ownCompany,
          x: chartX !== undefined ? chartX.value : 0,
          y: chartY !== undefined ? chartY.value : 0,
        },
        {
          title: titles.averageAll,
          x: chartX !== undefined ? chartX.averageAll : 0,
          y: chartY !== undefined ? chartY.averageAll : 0,
        },
    ];

    const isIndustryChosen = chartX !== undefined && chartX.averageIndustry > 0
                          && chartY !== undefined && chartY.averageIndustry > 0;

    if (isIndustryChosen) {
      markers.push(
        {
          title: titles.averageIndustry,
          x: chartX !== undefined ? chartX.averageIndustry : 0,
          y: chartY !== undefined ? chartY.averageIndustry : 0,
        }
      )
    }
    return markers;
}