import React, { FC } from "react";
import logoIf from '../img/if.png';
import logoSH from '../img/smarthome.png';
import { useStyles } from "../logos/Logos.style";

export const Logos: FC = () => {
  const styles = useStyles();

  return (
    <React.Fragment>
      <div className={styles.logoContainer}>
        <a rel="noopener noreferrer" target="_blank" href="https://mgmt.au.dk/smarthome/"><img alt="Smart Home" src={logoSH} className={styles.logo} title="Smart Home" /></a>
        <a rel="noopener noreferrer" target="_blank" href="https://www.industriensfond.dk/"><img alt="Industriens Fond" src={logoIf} className={styles.logo} title="Industriens Fond" /></a>
      </div>
    </React.Fragment>
  );
};
