import React, { FC, useEffect, useState } from "react";
import Typography from "@material-ui/core/Typography";
import { InjectedIntlProps, injectIntl, FormattedMessage } from "react-intl";
import { Layout } from "../layout/Layout";
import {
  QuestionObject,
  OptionObject,
  SelectedOptions,
  SurveyObject
} from "../customTypes";
import { RouteComponentProps } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { surveyActions } from "../actions/survey-actions";
import { RootState } from "../store";
import {
  FormGroup,
  FormControlLabel,
  Checkbox,
  Radio,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { paths } from "../router";
import { saveQuestionAnswer } from "../api/api";

export interface QuestionProps extends InjectedIntlProps {}

interface QuestionParams {
  questionIndex: number;
}
export const QuestionWrapped: FC<QuestionProps & RouteComponentProps> = (
  props: QuestionProps & RouteComponentProps
) => {
  // Initialize state
  const dispatch = useDispatch();
  const survey: SurveyObject = useSelector<RootState, SurveyObject>(
    state => state.surveyState.survey
  );
  const sessionUuid: string = useSelector<RootState, string>(
    state => state.surveyState.sessionUuid
  );
  const saveQuestionAnswerPending: boolean = useSelector<RootState, boolean>(
    state => state.surveyState.saveQuestionAnswerPending
  );
  const selectedOptions: SelectedOptions = useSelector<
    RootState,
    SelectedOptions
  >(state => state.surveyState.selectedOptions);

  // Get access to history
  const history = useHistory();

  const [dialogOpen, setDialogOpen] = useState(false);

  // Fetch question
  const questions = survey !== undefined ? survey.questions : [];
  let questionIndex: number = 0;
  let question: QuestionObject = {} as QuestionObject;
  if (props.match !== undefined && questions !== undefined) {
    const params: QuestionParams = props.match.params as QuestionParams;
    questionIndex = Number(params.questionIndex);
    if (questionIndex <= questions.length) {
      question = questions[questionIndex - 1];
    }
  }

  useEffect(() => {
    // If no question is found redirect to intro
    if (questions === undefined || question === undefined) {
      history.push(paths.intro);
    } else {
      dispatch(surveyActions.setPage(paths.question));
      dispatch(surveyActions.setQuestionIndex(questionIndex));
    }
  });

  if (questions === undefined || question === undefined) {
    return <div></div>;
  }

  const routeToNext = () => {
    if (questionIndex === questions.length) {
      history.push(paths.questionOutro);
    } else {
      history.push(paths.question + "/" + (questionIndex + 1));
    }
  };

  const routeToPrevious = () => {
    if (questionIndex <= 1) {
      history.push(paths.industry);
    } else {
      history.push(paths.question + "/" + (questionIndex - 1));
    }
  };

  const nextClickHandler = () => {
    if (selectedOptions[question.uuid] === undefined) {
      if (window.location.href.includes("localhost")) {
        routeToNext();
      } else {
        setDialogOpen(true);
      }
    } else {
      dispatch(
        saveQuestionAnswer(
          sessionUuid,
          survey.uuid,
          question.uuid,
          selectedOptions[question.uuid].map(o => o.uuid)
        )
      )
        .then(() => {
          routeToNext();
        })
        .catch(() => {
          console.log("Error saving answer");
        });
    }
  };

  const previousClickHandler = () => {
    if (selectedOptions[question.uuid] === undefined) {
      routeToPrevious();
    } else {
      dispatch(
        saveQuestionAnswer(
          sessionUuid,
          survey.uuid,
          question.uuid,
          selectedOptions[question.uuid].map(o => o.uuid)
        )
      )
        .then(() => {
          routeToPrevious();
        })
        .catch(() => {
          console.log("Error saving answer");
        });
    }
  };

  const checkboxClicked = (event: React.ChangeEvent<HTMLInputElement>) => {
    const option: OptionObject | undefined = findOption(question.options, event.target.value);
    if (event.target.checked) {
      dispatch(surveyActions.selectAnswer(question, option));
    } else {
      dispatch(surveyActions.deselectAnswer(question, option));
    }
  };

  const radioClicked = (event: React.ChangeEvent<HTMLInputElement>) => {
    const option: OptionObject | undefined = findOption(question.options, event.target.value);
    dispatch(surveyActions.selectAnswer(question, option));
  };

  const findOption = (options: OptionObject[], optionUuid: string): OptionObject | undefined => {
    return options.find(o => o.uuid === optionUuid);
  };

  const isOptionChecked = (option: OptionObject) => {
    let checked: boolean = false;
    if (selectedOptions[question.uuid] !== undefined) {
      for (const selectedOptionUuid of selectedOptions[question.uuid].map(o => o.uuid)) {
        if (selectedOptionUuid === option.uuid) {
          checked = true;
        }
      }
    }
    return checked;
  };

  return (
    <Layout
      nextClickHandler={nextClickHandler}
      previousClickHandler={previousClickHandler}
      currentIndex={questionIndex}
      totalItems={questions.length}
      loading={saveQuestionAnswerPending}
    >
      <React.Fragment>
        <Typography component="h1" variant="h4" align="left" gutterBottom>
          <FormattedMessage id="common.question" /> {questionIndex}
        </Typography>
        <Typography component="h1" variant="subtitle1" align="left">
          {question.text}
        </Typography>
        <FormGroup>
          {question.options !== undefined &&
            question.options.map((option: OptionObject, i: number) => {
              const checked: boolean = isOptionChecked(option);
              if (question.type === "checkbox") {
                return (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={checked}
                        onChange={checkboxClicked}
                        value={option.uuid}
                      />
                    }
                    label={option.text}
                    key={option.uuid}
                  />
                );
              } else {
                return (
                  <FormControlLabel
                    control={
                      <Radio
                        checked={checked}
                        onChange={radioClicked}
                        value={option.uuid}
                      />
                    }
                    label={option.text}
                    key={option.uuid}
                  />
                );
              }
            })}
        </FormGroup>
        {question.videoURL !== undefined && question.videoURL !== '' && (
          <React.Fragment>
            <iframe
              title="video"
              src={question.videoURL}
              width="100%"
              height="360"
              frameBorder="0"
              allow="autoplay; fullscreen"
              allowFullScreen
            ></iframe>
            <p>{question.videoText}</p>
          </React.Fragment>
        )}

          <Dialog
            open={dialogOpen}
          >
            <DialogContent>
              <DialogContentText>
                {props.intl.formatMessage({ id: "question.noOptionSelected" })}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => {
                  setDialogOpen(false);
                }} 
                color="primary">
                {props.intl.formatMessage({ id: "question.dialog.no" })}
              </Button>
              <Button onClick={() => {
                  setDialogOpen(false);
                  routeToNext();
                }} 
                color="primary" autoFocus>
                {props.intl.formatMessage({ id: "question.dialog.yes" })}
              </Button>
            </DialogActions>
          </Dialog>

      </React.Fragment>
    </Layout>
  );
};

export const Question = injectIntl(QuestionWrapped);
