import React, { FC } from "react";
import { paths } from "./router";
import { Intro } from "./intro/Intro";
import { Provider } from "react-redux";
import thunk from 'redux-thunk';
import "./App.css";
import { createStore, applyMiddleware } from "redux";
import rootReducer from "./store";
import { IntlProvider, addLocaleData } from "react-intl";
import locale_da from "react-intl/locale-data/da";
import messages_da from "./translations/da.json";
import { ThemeProvider } from "@material-ui/styles";
import { theme } from "./theme/theme";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import { Question } from "./question/Question";
import { RadarChartResult } from "./radar-chart-result/RadarChartResult";
import { MatrixResult } from "./matrix-result/MatrixResult";
import { Outro } from "./outro/Outro";
import { CompanyForm } from "./companyform/CompanyForm";
import { SemanticResult } from "./semantic-result/SemanticResult";
import { QuestionOutro } from "./question-outro/QuestionOutro";
import { Industry } from "./industry/Industry";

const middlewares = [thunk];
const store = createStore(rootReducer, applyMiddleware(...middlewares));

// Set up localization
const locale = "da";
addLocaleData([...locale_da]);
const messages = {
  da: messages_da
};

const App: FC = () => {
  return (
    <Provider store={store}>
      <IntlProvider locale={locale} messages={messages[locale]}>
        <ThemeProvider theme={theme}>
          <Router>
            <Switch>
              <Route path={paths.intro} component={Intro} />
              <Route path={paths.industry} component={Industry} />
              <Route path={paths.question+"/:questionIndex"} component={Question} />
              <Route path={paths.questionOutro} component={QuestionOutro} />
              <Route path={paths.radar} component={RadarChartResult} />
              <Route path={paths.matrix} component={MatrixResult} />
              <Route path={paths.companyForm} component={CompanyForm} />
              <Route path={paths.semantic} component={SemanticResult} />
              <Route path={paths.outro} component={Outro} />
              <Redirect from={paths.base} to={paths.intro} />
            </Switch>
          </Router>
        </ThemeProvider>
      </IntlProvider>
    </Provider>
  );
};

export default App;
