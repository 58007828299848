import React, { FC, useState } from "react";
import { useStyles, markerSize } from "./Matrix.style";
import { useStyles as useSquareStyles } from "./matrix-square/MatrixSquare.style";
import { InjectedIntlProps, injectIntl } from "react-intl";
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../store";
import { chartActions } from "../actions/chart-actions";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from '@material-ui/core/IconButton';
import { ChartDialog } from "../reducers/chart-reducer";
import { formatMultipleTexts } from "../util/chart-util";
import { MatrixMarker } from "../customTypes";
import { theme } from '../theme/theme';
import { MatrixSquare } from "./matrix-square/MatrixSquare";

interface MatrixProps extends InjectedIntlProps {
    labelX: string | undefined;
    labelY: string | undefined;
    markers: MatrixMarker[];
}

export const MatrixWrapped: FC<MatrixProps> = (props: MatrixProps) => {
  const styles = useStyles();
  const squareStyles = useSquareStyles();
  const dispatch = useDispatch();

  const [dialogOpen, setDialogOpen] = useState(false);
  const [selected, setSelected] = useState([true, true, true]);

  const dialog: ChartDialog = useSelector<RootState, ChartDialog>(
    state => state.chart.dialog
  );

  const handleClickOpen = () => {
    setDialogOpen(true);
  };
  const handleClose = () => {
    setDialogOpen(false);
  };

  const dialogContent = (content: string[]) => {
    let items: any[] = [];
    content.forEach((item, index) => {
      items.push(
        <li key={index}>{item}</li>
      )
    })
    return <ul>{items}</ul>;
  };

  const legend = () => {
    let legends: any[] = [];
    props.markers.forEach((m, index) => {
      let classes = [styles.legend];
      if (selected[index]) {
        classes.push(styles.legendSelected);
      }
      legends.push(
        <li 
          key={index} 
          className={classes.join(' ')}>
          <a 
            href="#0"
            className={styles.legendLink}
            onClick={() => {
                let selectedState = selected;
                selectedState[index] = !selected[index];
                setSelected(selectedState);
              }
            }
          >
            <div 
              className={styles.legendMarker} 
              style={{backgroundColor: theme.palette.chartEntries[index].marker.main}}>
            </div>{m.title}
          </a>
        </li>
      )
    });
    return <ul className={styles.legends}>{legends}</ul>;
  };

  const markerStyle = (index: number) => {
    return {
      bottom: `calc(${props.markers[index].y}% - ${markerSize/2}px)`,
      left: `calc(${props.markers[index].x}% - ${markerSize/2}px)`,
      backgroundColor: theme.palette.chartEntries[index].marker.main,
    }
  }

  const markers = () => {
    let markers: any[] = [];
    props.markers.forEach((m, index) => {
      if (selected[index]) {
        markers.push(
          <div key={index} className={styles.marker} style={markerStyle(index)}></div> 
        )
      }
    });
    return markers;
  };

  return (
    <React.Fragment>
      <div className={styles.matrixLegend}>
        {legend()}
      </div>

      <div className={styles.matrixContainer}>
        <div>
          <div className={styles.yAxis}>
            { props.labelX }  →
          </div>
          <div className={styles.matrix}>
          
            <MatrixSquare 
              label={props.intl.formatMessage({id: 'matrix.leftUpper'})}
              class={squareStyles.leftUpper}
              toggleDialog={
                () => {
                  dispatch(chartActions.updateDialog(
                    {
                      title: props.intl.formatMessage({ id: "matrix.leftUpper" }),
                      content: props.intl.formatMessage({ id: "matrix.leftUpperMore" })
                    }
                  ));
                  handleClickOpen();
                }
              } 
            />

            <MatrixSquare 
              label={props.intl.formatMessage({id: 'matrix.rightUpper'})}
              class={squareStyles.rightUpper}
              toggleDialog={
                () => {
                  dispatch(chartActions.updateDialog(
                    {
                      title: props.intl.formatMessage({ id: "matrix.rightUpper" }),
                      content: props.intl.formatMessage({ id: "matrix.rightUpperMore" })
                    }
                  ));
                  handleClickOpen();
                }
              } 
            />

            <MatrixSquare 
              label={props.intl.formatMessage({id: 'matrix.leftLower'})}
              class={squareStyles.leftLower}
              toggleDialog={
                () => {
                  dispatch(chartActions.updateDialog(
                    {
                      title: props.intl.formatMessage({ id: "matrix.leftLower" }),
                      content: props.intl.formatMessage({ id: "matrix.leftLowerMore" })
                    }
                  ));
                  handleClickOpen();
                }
              } 
            />

            <MatrixSquare 
              label={props.intl.formatMessage({id: 'matrix.rightLower'})}
              class={squareStyles.rightLower}
              toggleDialog={
                () => {
                  dispatch(chartActions.updateDialog(
                    {
                      title: props.intl.formatMessage({ id: "matrix.rightLower" }),
                      content: props.intl.formatMessage({ id: "matrix.rightLowerMore" })
                    }
                  ));
                  handleClickOpen();
                }
              } 
            />
            
            {markers()}
          </div>
        </div>
        <div className={styles.xAxis}>
          { props.labelY } →
        </div>

        <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={dialogOpen}>
          <DialogTitle id="customized-dialog-title" className={styles.dialogTitleContainer}>
            <div className={styles.dialogTitle}>{dialog.title}</div>
            <div className={styles.closeButton}>
            <IconButton aria-label="close" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
            </div>
          </DialogTitle>
          <DialogContent dividers className={styles.dialogContent}>
            {dialogContent(formatMultipleTexts(dialog.content))}
          </DialogContent>
        </Dialog>

      </div>
    </React.Fragment>
  );
};

export const Matrix = injectIntl(MatrixWrapped);