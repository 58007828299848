import { ChartDialog, SemanticCategory } from "../reducers/chart-reducer";

type ChartActionType = 'FETCH_STATISTICS_PENDING' | 'FETCH_STATISTICS_SUCCESS' | 'FETCH_STATISTICS_ERROR' | 'UPDATE_DIALOG' | 'UPDATE_SEMANTIC_CATEGORY';

export interface ChartAction {
    type: ChartActionType;
    payload?: any;
}

interface ChartActions {
    fetchStatisticsPending: () => ChartAction;
    fetchStatisticsSuccess: (payload: any) => ChartAction;
    fetchStatisticsError: (error: string) => ChartAction;
    updateDialog: (payload: ChartDialog) => ChartAction;
    updateSemanticCategory: (payload: SemanticCategory) => ChartAction;
}

export const chartActions: ChartActions = {
    fetchStatisticsPending: () => {
        return {
          type: "FETCH_STATISTICS_PENDING"
        };
      },
    fetchStatisticsSuccess: (payload: any) => {
        return {
          type: "FETCH_STATISTICS_SUCCESS",
          payload
        };
      },
    fetchStatisticsError: (error: string) => {
        return {
          type: "FETCH_STATISTICS_ERROR",
          payload: error
        };
    },
    updateDialog: (payload: ChartDialog) => {
        return {
          type: "UPDATE_DIALOG",
          payload
        };
    },
    updateSemanticCategory: (payload: SemanticCategory) => {
      return {
        type: 'UPDATE_SEMANTIC_CATEGORY',
        payload
      }
    }
}