import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
    root: {
    },
    buttons: {
        marginTop: theme.spacing(5)
    },
    buttonLink: {
        textDecoration: "none"
    }
}));
