/**
 * Clone object.
 * @param object Object to clone.
 * @return Cloned object if success, otherwise return empty object.
 */
export const cloneObject = (object: {}) => {
  try {
    // Deep copy per: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/assign#Deep_Clone
    return JSON.parse(JSON.stringify(object));
  } catch (error) {
    console.log(error);
  }
  return {};
};

export const mergeObjects = (objectA: any, objectB: any) => {
  return {...objectA, ... objectB};
}

/**
 * Get identifier from a given YouTube url.
 * @param {string} url YouTube url.
 * @returns {string} Identifier of YouTube video if found.
 */
export const getYouTubeId = (url: string) => {
  let regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
  let match = url.match(regExp);
  return match && match[7].length === 11 ? match[7] : "";
};

/**
 * Add company name to text via special tag
 * @param {string} text text.
 * @param {string} companyName company name.
 * @returns {string} Text with tags replaced by company name
 */
export const addCompanyName = (text: string, companyName: string): string => {
  const newText = text.replace(/\[\[company\]\]/g, companyName);
  return newText;
}