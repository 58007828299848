import React, { FC, useEffect } from "react";
import Typography from "@material-ui/core/Typography";
import { FormattedMessage, InjectedIntlProps, injectIntl } from "react-intl";
import { Layout } from "../layout/Layout";
import { useDispatch, useSelector } from "react-redux";
import { surveyActions } from "../actions/survey-actions";
import { paths } from "../router";
import { NavLink, useHistory } from "react-router-dom";
import { Button } from "@material-ui/core";
import { useStyles } from "./Outro.style";
import { SurveyObject } from "../customTypes";
import { RootState } from "../store";
import { Logos } from "../logos/Logos";

export interface OutroProps extends InjectedIntlProps {}

export const OutroWrapped: FC<OutroProps> = (props: OutroProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const survey: SurveyObject = useSelector<RootState, SurveyObject>(
    state => state.surveyState.survey
  );
  useEffect(() => {
    // If no questions are found redirect to intro
    if (survey.questions === undefined) {
      history.push(paths.intro);
    } else {
      dispatch(surveyActions.setPage(paths.outro));
      dispatch(surveyActions.setQuestionIndex(0));
    }
  }, []);

  if (survey.questions === undefined) {
    return <div></div>;
  }

  return (
    <Layout hideFooter={true}>
      <React.Fragment>
        <Typography component="h1" variant="h4" align="left" gutterBottom>
          <FormattedMessage id="outro.title" />
        </Typography>
        <div className={classes.root}>
          <div
            dangerouslySetInnerHTML={{
              __html:
                survey.outroScreen !== undefined ? survey.outroScreen.text : ""
            }}
          />
          {survey.outroScreen.videoURL !== undefined &&
            survey.outroScreen.videoURL !== "" && (
              <React.Fragment>
                <iframe
                  title="video"
                  src={survey.outroScreen.videoURL}
                  width="100%"
                  height="360"
                  frameBorder="0"
                  allow="autoplay; fullscreen"
                  allowFullScreen
                ></iframe>
              </React.Fragment>
            )}
          <div className={classes.buttons}>
            <p>
              <NavLink to={paths.intro} className={classes.buttonLink}>
                <Button color="primary" variant="contained">
                  {props.intl.formatMessage({ id: "outro.takeSurveyAgain" })}
                </Button>
              </NavLink>
            </p>
          </div>
          <div className={classes.logoContainer}>
            <Logos />
          </div>
        </div>
      </React.Fragment>
    </Layout>
  );
};

export const Outro = injectIntl(OutroWrapped);
