import React, { FC, useEffect } from "react";
import Typography from "@material-ui/core/Typography";
import { FormattedMessage, InjectedIntlProps, injectIntl } from "react-intl";
import { Layout } from "../layout/Layout";
import { useDispatch, useSelector } from "react-redux";
import { surveyActions } from "../actions/survey-actions";
import { fetchSurvey } from "../api/api";
import { paths } from "../router";
import { SurveyObject } from "../customTypes";
import { RootState } from "../store";
import { NavLink } from "react-router-dom";
import { Button } from "@material-ui/core";
import { useStyles } from "./Intro.style";
import { CircularProgress } from "@material-ui/core";
import { Logos } from "../logos/Logos";

export interface IntroProps extends InjectedIntlProps {}

export const IntroWrapped: FC<IntroProps> = (props: IntroProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const survey: SurveyObject = useSelector<RootState, SurveyObject>(
    state => state.surveyState.survey
  );
  const pending: boolean = useSelector<RootState, boolean>(
    state => state.surveyState.pending
  );
  const error: string = useSelector<RootState, string>(
    state => state.surveyState.error
  );

  useEffect(() => {
    window.history.pushState("", "", "/");

    if (survey.questions === undefined) {
      dispatch(fetchSurvey());
    }
    dispatch(surveyActions.setPage(paths.intro));
    dispatch(surveyActions.setQuestionIndex(0));
  }, []);

  const loadButtonClicked = () => {
    dispatch(fetchSurvey());
  };

  return (
    <Layout hideFooter={true}>
      <React.Fragment>
        {pending && (
          <div>
            <Typography component="h1" variant="h4" align="left" gutterBottom>
              <FormattedMessage id="intro.loadingTitle" />
            </Typography>
            <FormattedMessage id="intro.loadingText" />
            <div>
              <CircularProgress />
            </div>
          </div>
        )}
        {!pending && survey.questions !== undefined && (
          <div>
            <Typography component="h1" variant="h4" align="left">
              <FormattedMessage id="intro.title" />
            </Typography>
            <div
              dangerouslySetInnerHTML={{
                __html:
                  survey.introScreen !== undefined
                    ? survey.introScreen.text
                    : ""
              }}
            />

            <div className={classes.startButton}>
              <NavLink
                to={paths.industry}
                className={classes.buttonLink}
              >
                <Button color="primary" variant="contained">
                  {props.intl.formatMessage({ id: "intro.startSurvey" })}
                </Button>
              </NavLink>
            </div>

            {survey.introScreen.videoURL !== undefined && survey.introScreen.videoURL !== "" && (
              <React.Fragment>
                <iframe
                  title="video"
                  src={survey.introScreen.videoURL}
                  width="100%"
                  height="360"
                  frameBorder="0"
                  allow="autoplay; fullscreen"
                  allowFullScreen
                ></iframe>
              </React.Fragment>
            )}
            <Logos />
          </div>
        )}
        {survey.questions === undefined && !pending && error !== "" && (
          <div>
            <Typography component="h1" variant="h4" align="left">
              <FormattedMessage id="intro.loadFailedTitle" />
            </Typography>
            <FormattedMessage id="intro.loadFailed" />
            <div className={classes.startButton}>
              <Button
                color="primary"
                variant="contained"
                onClick={loadButtonClicked}
              >
                {props.intl.formatMessage({ id: "intro.tryLoadAgain" })}
              </Button>
            </div>
          </div>
        )}
      </React.Fragment>
    </Layout>
  );
};

export const Intro = injectIntl(IntroWrapped);
