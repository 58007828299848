import { SurveyAction } from "../actions/survey-actions";
import {
  QuestionObject,
  SelectedOptions,
  SelectedOption,
  SurveyObject,
  CompanyInformation,
  OptionObject,
  Feedback,
} from "../customTypes";
import { mergeObjects } from "../util/util";

export function surveyReducer(
  state: SurveyState = surveyStateInitial,
  action: SurveyAction
) {
  switch (action.type) {
    case "ANSWER_SELECTED":
      const questionObj = action.payload.question as QuestionObject;
      const optionObj = action.payload.option as OptionObject;
      
      let newSelectedOptions: SelectedOption[] = [];
      let selectedOption: SelectedOption = {uuid: optionObj.uuid};

      if (optionObj.feedbackLevel && optionObj.feedbackLevel !== "none" && questionObj.feedback 
        && Object.keys(questionObj.feedback).length !== 0) {
        const feedback: string = (questionObj.feedback as Feedback)[optionObj.feedbackLevel];
        selectedOption.feedback = feedback ? feedback : ""; 
      }

      if (state.selectedOptions[questionObj.uuid] !== undefined) {
        if (questionObj.type === "checkbox") {
          newSelectedOptions = state.selectedOptions[questionObj.uuid];
          newSelectedOptions.push(selectedOption);
        } else {
          newSelectedOptions = [selectedOption];
        }
      } else {
        newSelectedOptions = [selectedOption];
      }
      return {
        ...state,
        selectedOptions: {
          ...state.selectedOptions,
          [questionObj.uuid]: newSelectedOptions
        }
      };
    case "ANSWER_DESELECTED":
      const questionDeselected = action.payload.question as QuestionObject;
      const optionDeselectedObject = action.payload.option as OptionObject;
      const stateOptions: SelectedOption[] = state.selectedOptions[questionDeselected.uuid];

      let newDeselectedOptions: SelectedOption[] = stateOptions;
      if (stateOptions.map(o => o.uuid).includes(optionDeselectedObject.uuid)) {
        newDeselectedOptions = stateOptions.filter(o => o.uuid !== optionDeselectedObject.uuid);
      }

      return {
        ...state,
        selectedOptions: {
          ...state.selectedOptions,
          [questionDeselected.uuid]: newDeselectedOptions
        }
      };
    case "COMPANY_INFORMATION_SET":
      return {
        ...state,
        companyInformation: mergeObjects(state.companyInformation, action.payload)
      };
    case "FETCH_SURVEY_PENDING":
      return {
        ...state,
        pending: true
      };
    case "FETCH_SURVEY_SUCCESS":
      return {
        ...state,
        pending: false,
        survey: action.payload
      };
    case "FETCH_SURVEY_ERROR":
      return {
        ...state,
        pending: false,
        error: action.payload
      };
    case "PAGE_SET":
      return {
        ...state,
        currentPage: action.payload
      };
    case "QUESTION_INDEX_SET":
      return {
        ...state,
        currentQuestionIndex: action.payload
      };
    case "SAVE_COMPANY_INFORMATION_PENDING":
      return {
        ...state,
        saveCompanyInformationError: "",
        saveCompanyInformationPending: true
      };
    case "SAVE_COMPANY_INFORMATION_SUCCESS":
      return {
        ...state,
        saveCompanyInformationError: "",
        saveCompanyInformationPending: false,
        sessionUuid:
          action.payload !== undefined && action.payload !== ""
            ? action.payload
            : state.sessionUuid
      };
    case "SAVE_COMPANY_INFORMATION_ERROR":
      return {
        ...state,
        saveCompanyInformationPending: false,
        saveCompanyInformationError: action.payload
      };
    case "SAVE_QUESTION_ANSWER_PENDING":
      return {
        ...state,
        saveQuestionAnswerError: "",
        saveQuestionAnswerPending: true
      };
    case "SAVE_QUESTION_ANSWER_SUCCESS":
      return {
        ...state,
        saveQuestionAnswerPending: false,
        saveQuestionAnswerError: "",
        sessionUuid:
          action.payload !== undefined && action.payload !== ""
            ? action.payload
            : state.sessionUuid
      };
    case "SAVE_QUESTION_ANSWER_ERROR":
      return {
        ...state,
        saveQuestionAnswerPending: false,
        saveQuestionAnswerError: action.payload
      };
    default:
      return state;
  }
}

export interface SurveyState {
  pending: boolean;
  error: string;
  saveCompanyInformationPending: boolean;
  saveCompanyInformationError: string;
  saveQuestionAnswerPending: boolean;
  saveQuestionAnswerError: string;
  currentPage: string;
  currentQuestionIndex: number;
  companyInformation: CompanyInformation;
  survey: SurveyObject;
  questions: QuestionObject[];
  selectedOptions: SelectedOptions;
  sessionUuid: string;
}

export const surveyStateInitial: SurveyState = {
  pending: false,
  error: "",
  saveCompanyInformationPending: false,
  saveCompanyInformationError: "",
  saveQuestionAnswerPending: false,
  saveQuestionAnswerError: "",
  currentPage: "intro",
  currentQuestionIndex: 0,
  companyInformation: {},
  survey: {} as SurveyObject,
  questions: [],
  selectedOptions: {},
  sessionUuid: ""
};
