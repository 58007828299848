import React, { FC, useEffect } from "react";
import Typography from "@material-ui/core/Typography";
import { useDispatch, useSelector } from "react-redux";
import { InjectedIntlProps, injectIntl, FormattedMessage } from "react-intl";
import { RadarChart } from "../radar-chart/RadarChart";
import { RootState } from "../store";
import { useStyles } from "./RadarChartResult.style";
import {
  labelIdentifiers,
  createLabelsWithIdentifiers,
  createEntries
} from "../util/chart-util";
import { Layout } from "../layout/Layout";
import { surveyActions } from "../actions/survey-actions";
import { paths } from "../router";
import { SurveyObject } from "../customTypes";
import { useHistory } from "react-router-dom";
import { fetchStatistics } from "../api/api";

export const RadarChartResultWrapped: FC<InjectedIntlProps> = (
  props: InjectedIntlProps
) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const survey: SurveyObject = useSelector<RootState, SurveyObject>(
    state => state.surveyState.survey
  );
  const values: number[] = useSelector<RootState, number[]>(
    state => state.chart.radar.map(item => item.value)
  );
  const averagesAll: number[] = useSelector<RootState, number[]>(
    state => state.chart.radar.map(item => item.averageAll)
  );
  const averagesIndustry: number[] = useSelector<RootState, number[]>(
    state => state.chart.radar.map(item => item.averageIndustry)
  );
  const labels: string[] = useSelector<RootState, string[]>(
    state => state.chart.radar.map(item => item.label)
  );
  const sessionUuid: string = useSelector<RootState, string>(
    state => state.surveyState.sessionUuid
  );
  useEffect(() => {
    if (survey.questions === undefined) {
      history.push(paths.intro);
      return;
    }
    dispatch(surveyActions.setPage(paths.radar));
    dispatch(surveyActions.setQuestionIndex(0));

    dispatch(fetchStatistics(sessionUuid));
  }, []);

  if (survey.questions === undefined) {
    return <div></div>
  }

  return (
    <Layout
      nextPath={paths.matrix}
      previousPath={paths.questionOutro}
    >
      <Typography component="h1" variant="h4" align="left">
        <FormattedMessage id="results.spider" />
      </Typography>

      <div className={styles.chartContainer}>
        <RadarChart
          labels={createLabelsWithIdentifiers(labels, labelIdentifiers)}
          entries={createEntries(props, values, averagesAll, averagesIndustry)}
        />
      </div>
    </Layout>
  );
};

export const RadarChartResult = injectIntl(RadarChartResultWrapped);
