import { surveyReducer, SurveyState, surveyStateInitial } from "./reducers/survey-reducer";
import { chartReducer, ChartState, chartStateInitial } from "./reducers/chart-reducer";

const rootReducer = (state: RootState = {
    surveyState: surveyStateInitial,
    chart: chartStateInitial
  }, action: any) => {
  return {
    surveyState: surveyReducer(state.surveyState, action),
    chart: chartReducer(state.chart, action),
  }
};
  
export interface RootState {
  surveyState: SurveyState;
  chart: ChartState;
}

export const rootStateInitial = {
  surveyState: surveyStateInitial,
  chart: chartStateInitial,
}

export default rootReducer;