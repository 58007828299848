import { makeStyles } from '@material-ui/core/styles';

export const markerSize = 16;

export const useStyles = makeStyles(theme => ({
    matrix: {
        position: 'relative',
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
    },
    marker: {
        borderRadius: '50%',
        opacity: 0.8,
        height: markerSize,
        width: markerSize,
        backgroundColor: '#000',
        position: 'absolute',
        border: '2px solid white',
    },   
    matrixLegend: {
        paddingLeft: 16,
    },
    legends: {
        listStyle: 'none',
        padding: 0,
        margin: 0,
        marginTop: 8,
    },
    legend: {
        padding: 0,
        paddingTop: 8,
        textDecoration: 'line-through',
    },
    legendLink: {
        textDecoration: 'none',
        color: '#000',
    },
    legendSelected: {
        textDecoration: 'none',
    },
    legendMarker: {
        width: 12,
        height: 12,
        background: '#000',
        float: 'left',
        borderRadius: '50%',
        margin: 8,
        marginTop: 4,
    },
    matrixContainer: {
        paddingTop: 16,
    },
    xAxis: {
        paddingLeft: 36,
        paddingTop: 4,
        textAlign: 'left',
        color: theme.palette.matrixChart.axes.main,
    },
    yAxis: {
        float: 'left',
        writingMode: 'vertical-lr',
        width: 24,
        height: 400,
        transform: 'rotate(180deg)',
        marginRight: 4,
        paddingTop: 16,
        color: theme.palette.matrixChart.axes.main,
    },
    closeButton: {
        marginLeft: theme.spacing(2),
        color: theme.palette.grey[500],
        float: 'right',
    },
    dialogTitle: {
        float: 'left',
        lineHeight: '48px',
    },
    dialogTitleContainer: {
        paddingTop: 0,
        paddingBottom: 0,
        paddingRight: 0,
    },
    dialogContent: {
        paddingLeft: 0,
        paddingTop: 0,
        paddingBottom: 0,
        fontSize: 16,
    }
}));
