import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  main: {
    fontSize: 16
  },
  feedback: {
    marginTop: theme.spacing(4),
  },
  printIconContainer: {
    textAlign: 'center',
    width: '100%',
    marginTop: theme.spacing(5),
    height: '3rem',
  },
  chartContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  pageBreakBefore: {
    '@media print': {
      pageBreakBefore: 'always'
    }
  },
  resultsButton: {
    marginTop: theme.spacing(4),
  }
}));
