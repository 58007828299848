import React, { FC, useEffect } from "react";
import { InjectedIntlProps, injectIntl, FormattedMessage } from "react-intl";
import { Layout } from "../layout/Layout";
import { Matrix } from "../matrix/Matrix";
import { paths } from "../router";
import { useDispatch, useSelector } from "react-redux";
import { surveyActions } from "../actions/survey-actions";
import Typography from "@material-ui/core/Typography";
import { SurveyObject, MatrixTitles } from "../customTypes";
import { RootState } from "../store";
import { useHistory } from "react-router-dom";
import { fetchStatistics } from "../api/api";
import { ChartValue } from "../reducers/chart-reducer";
import { getMatrixChartX, getMatrixChartY, getMatrixMarkers } from "../util/chart-util";

export const MatrixResultWrapped: FC<InjectedIntlProps> = (
  props: InjectedIntlProps
) => {

  const dispatch = useDispatch();
  const history = useHistory();
  const survey: SurveyObject = useSelector<RootState, SurveyObject>(
    state => state.surveyState.survey
  );
  const chartValues: ChartValue[] = useSelector<RootState, ChartValue[]>(
    state => state.chart.matrix
  );
  const sessionUuid: string = useSelector<RootState, string>(
    state => state.surveyState.sessionUuid
  );
  useEffect(() => {
    // If no questions are found redirect to intro
    if (survey.questions === undefined) {
      history.push(paths.intro);
    } else {
      dispatch(surveyActions.setPage(paths.matrix));
      dispatch(surveyActions.setQuestionIndex(0));
    }

    dispatch(fetchStatistics(sessionUuid));
  }, []);

  if (survey.questions === undefined) {
    return <div></div>;
  }

  const chartX = getMatrixChartX(chartValues);
  const chartY = getMatrixChartY(chartValues);

  const labelX: string = chartX !== undefined ? chartX.label : 'x';
  const labelY: string = chartY !== undefined ? chartY.label : 'y';

  const titles: MatrixTitles = {
    ownCompany: props.intl.formatMessage({ id: "results.yourCompany" }),
    averageAll: props.intl.formatMessage({ id: "results.averageAll" }),
    averageIndustry: props.intl.formatMessage({ id: "results.averageIndustry" }),
  }

  return (
    <Layout
      nextPath={paths.companyForm}
      previousPath={paths.radar}
    >
      <Typography component="h1" variant="h4" align="left">
        <FormattedMessage id="results.matrix" />
      </Typography>
      <Matrix 
        labelX={labelX} 
        labelY={labelY}
        markers={getMatrixMarkers(chartX, chartY, titles)}
      />
    </Layout>
  );
};

export const MatrixResult = injectIntl(MatrixResultWrapped);
