import React, { FC, useEffect } from "react";
import Typography from "@material-ui/core/Typography";
import { FormattedMessage, InjectedIntlProps, injectIntl } from "react-intl";
import { Layout } from "../layout/Layout";
import { useDispatch, useSelector } from "react-redux";
import { surveyActions } from "../actions/survey-actions";
import { paths } from "../router";
import { TextField, Button } from "@material-ui/core"
import {
  CompanyInformation,
  SurveyObject
} from "../customTypes";
import { RootState } from "../store";
import { useStyles } from "./CompanyForm.style";
import { useHistory, NavLink } from "react-router-dom";
import { cloneObject } from "../util/util";
import { saveCompanyInformation } from "../api/api";

export interface CompanyFormProps extends InjectedIntlProps {}

export const CompanyFormWrapped: FC<CompanyFormProps> = (
  props: CompanyFormProps
) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const survey: SurveyObject = useSelector<RootState, SurveyObject>(
    state => state.surveyState.survey
  );
  const sessionUuid: string = useSelector<RootState, string>(
    state => state.surveyState.sessionUuid
  );
  const companyInformation: CompanyInformation = useSelector<
    RootState,
    CompanyInformation
  >(state => state.surveyState.companyInformation);

  useEffect(() => {
    // If no questions are found redirect to intro
    if (survey.questions === undefined) {
      history.push(paths.intro);
    } else {
      dispatch(surveyActions.setPage(paths.companyForm));
      dispatch(surveyActions.setQuestionIndex(0));
    }
  });

  const selectChanged = (
    e: React.ChangeEvent<{ name?: string | undefined; value: unknown }>
  ) => {
    const information = cloneObject(companyInformation);
    information.industryCode = e.target.value;
    dispatch(surveyActions.setCompanyInformation(information));
  };

  const textFieldChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    const information = cloneObject(companyInformation);
    switch (e.target.id) {
      case "name":
        information.name = e.target.value;
        break;
      case "cvr":
        information.cvr = e.target.value;
        break;
      case "email":
        information.email = e.target.value;
        break;
    }
    dispatch(surveyActions.setCompanyInformation(information));
  };

  if (survey.questions === undefined) {
    return <div></div>;
  }

  const routeToNext = () => {
    history.push(paths.semantic);
  };

  const nextClickHandler = () => {
    if (companyInformation === undefined || companyInformation.cvr === undefined
      || companyInformation.name === "" || companyInformation.cvr === "") {
      alert(props.intl.formatMessage({ id: "companyForm.missingInformation" }));
      return;
    }
    dispatch(
      saveCompanyInformation(sessionUuid, survey.uuid, companyInformation)
    )
      .then(() => {
        routeToNext();
      })
      .catch(() => {
        console.log("Error saving company information");
      });
  };

  return (
    <Layout hideFooter={true}>
      <React.Fragment>
        <Typography component="h1" variant="h4" align="left" gutterBottom>
          <FormattedMessage id="companyForm.title" />
        </Typography>
        <p>
          <FormattedMessage id="companyForm.description" />
        </p>
        <div className={styles.informationBlock}>
          <FormattedMessage id="companyForm.name" />
          <br />
          <TextField
            id="name"
            defaultValue={companyInformation.name}
            onChange={textFieldChanged}
            placeholder={props.intl.formatMessage({
              id: "companyForm.namePlaceholder"
            })}
            fullWidth
          />
        </div>
        <div className={styles.informationBlock}>
          <FormattedMessage id="companyForm.cvr" />
          <br />
          <TextField
            id="cvr"
            defaultValue={companyInformation.cvr}
            onChange={textFieldChanged}
            placeholder={props.intl.formatMessage({
              id: "companyForm.cvrPlaceholder"
            })}
            type="number"
            fullWidth
          />
        </div>

        <div className={styles.informationBlock}>
          <FormattedMessage id="companyForm.mail" />
          <br />
          <TextField
            id="email"
            defaultValue={companyInformation.email}
            onChange={textFieldChanged}
            placeholder={props.intl.formatMessage({
              id: "companyForm.mailPlaceholder"
            })}
            type="email"
            fullWidth
          />
        </div>

        <p>
          <FormattedMessage id="companyForm.GDPR" />
        </p>

        <p>
          <Button
            color="primary"
            variant="contained"
            onClick={nextClickHandler}
          >
            {props.intl.formatMessage({ id: "companyForm.recommendations" })}
          </Button>
        </p>
        <p>
          <NavLink to={paths.outro} className={styles.buttonLink}>
            <Button variant="contained">
              {props.intl.formatMessage({ id: "companyForm.skip" })}
            </Button>
          </NavLink>
        </p>
        <p>
          <NavLink to={paths.matrix} className={styles.buttonLink}>
            <Button variant="contained">
              {props.intl.formatMessage({ id: "companyForm.back" })}
            </Button>
          </NavLink>
        </p>
      </React.Fragment>
    </Layout>
  );
};

export const CompanyForm = injectIntl(CompanyFormWrapped);
