type Mode = "dev" | "prod";

const devServer: Server = {
    serverUrl: "https://guybrush.alexandra.dk:450/ws/v1/",
    token: "131839c8-29be-4474-a839-44e733bcd60f",
}

const prodServer: Server = {
    serverUrl: "https://digitalmodenhed-admin.au.dk/ws/v1/",
    token: "53fe18a1-ac83-4f5d-984d-5d793a7049c3",
}

interface Server {
    readonly serverUrl: string;
    readonly token: string;
}

interface Config {
    readonly mode: Mode;
    readonly server: Server;
}

// setup server etc. here
// mode can be set to 'dev' or 'prod'
// mode is automatically set to dev if on localhost or guybrush 
const isDev = window.location.href.includes("localhost") || window.location.href.includes("guybrush");
export const config: Config = {
    mode: isDev ? "dev" : "prod",
    get server() {
        return this.mode === "dev" ? devServer : prodServer;
    } 
}
