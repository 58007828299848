export const paths = {
    base: '/',
    companyForm: '/companyform',
    industry: '/industry',
    intro: '/intro',
    outro: '/outro',
    question: '/question',
    questionOutro: '/questionoutro',
    radar: '/radar',
    matrix: '/matrix',
    semantic: '/semantic'
  };
  