import React, { FC, useEffect } from "react";
import Typography from "@material-ui/core/Typography";
import { FormattedMessage, InjectedIntlProps, injectIntl } from "react-intl";
import { Layout } from "../layout/Layout";
import { useDispatch, useSelector } from "react-redux";
import { surveyActions } from "../actions/survey-actions";
import { paths } from "../router";
import { useHistory } from "react-router-dom";
import { useStyles } from "./QuestionOutro.style";
import { SurveyObject } from "../customTypes";
import { RootState } from "../store";

export interface QuestionOutroProps extends InjectedIntlProps {}

export const QuestionOutroWrapped: FC<QuestionOutroProps> = (
  props: QuestionOutroProps
) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const survey: SurveyObject = useSelector<RootState, SurveyObject>(
    state => state.surveyState.survey
  );
  useEffect(() => {
    // If no questions are found redirect to intro
    if (survey.questions === undefined) {
      history.push(paths.intro);
    } else {
      dispatch(surveyActions.setPage(paths.questionOutro));
      dispatch(surveyActions.setQuestionIndex(0));
    }
  }, []);

  if (survey.questions === undefined) {
    return <div></div>;
  }

  return (
    <Layout
      previousPath={paths.question + "/" + survey.questions.length}
      nextPath={paths.radar}
    >
      <React.Fragment>
        <Typography component="h1" variant="h4" align="left" gutterBottom>
          <FormattedMessage id="questionOutro.title" />
        </Typography>
        <div className={classes.root}>
          <div
            dangerouslySetInnerHTML={{
              __html:
                survey.questionOutroScreen !== undefined ? survey.questionOutroScreen.text : ""
            }}
          />
        </div>
      </React.Fragment>
    </Layout>
  );
};

export const QuestionOutro = injectIntl(QuestionOutroWrapped);
