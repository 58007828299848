import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
    informationBlock: {
        marginBottom: theme.spacing(3)
    },
    buttonLink: {
        textDecoration: "none"
    }
}));
