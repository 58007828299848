import { surveyActions } from "../actions/survey-actions";
import { CompanyInformation, CompanyInformationAnswer } from "../customTypes";
import { chartActions } from "../actions/chart-actions";
import { config } from "../config";

const url = config.server.serverUrl;
const token = config.server.token;

export const fetchSurvey = () => {
  return (dispatch: any) => {
    dispatch(surveyActions.fetchSurveyPending());
    return fetch(url + "survey/?token=" + token)
      .then(res => res.json())
      .then(res => {
        if (res.error) {
          throw res.error;
        }
        res.survey.industryCodes = res.industryCodes;
        dispatch(surveyActions.fetchSurveySuccess(res.survey));
        return res.survey;
      })
      .catch(error => {
        dispatch(surveyActions.fetchSurveyError(error));
        throw error;
      });
  };
};

export const fetchStatistics = (sessionUuid: string) => {
  return (dispatch: any) => {
    dispatch(chartActions.fetchStatisticsPending());
    return fetch(url + 'statistics/?answerSessionUuid=' + sessionUuid + '&token=' + token)
      .then(res => res.json())
      .then(res => {
        if (res.error) {
          throw res.error;
        }
        dispatch(chartActions.fetchStatisticsSuccess(res));
        return res;
      })
      .catch(error => {
        dispatch(chartActions.fetchStatisticsError(error));
        throw error;
      })
  };
}

export const saveQuestionAnswer: (
  sessionUuid: string,
  surveyUuid: string,
  questionUuid: string,
  options: string[]
) => any = (
  sessionUuid: string,
  surveyUuid: string,
  questionUuid: string,
  options: string[]
) => {
  return (dispatch: any) => {
    const answers = [];
    for (const optionUuid of options) {
      answers.push({
        type: "answer",
        questionUuid: questionUuid,
        selection: optionUuid
      });
    }

    let body = {};
    if (sessionUuid !== "") {
      body = {
        answerSessionUuid: sessionUuid,
        answers: answers
      };
    } else {
      body = {
        surveyUuid: surveyUuid,
        answers: answers
      };
    }

    dispatch(surveyActions.saveQuestionAnswerPending());
    return fetch(url + "survey/?token=" + token, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify(body)
    })
      .then(res => res.json())
      .then(res => {
        if (res.error) {
          throw res.error;
        }
        const sessionUuid =
          res.session !== undefined && res.session.uuid !== undefined
            ? res.session.uuid
            : "";
        dispatch(surveyActions.saveQuestionAnswerSuccess(sessionUuid));
        return sessionUuid;
      })
      .catch(error => {
        dispatch(surveyActions.saveQuestionAnswerError(error));
        throw error;
      });
  };
};

export const saveCompanyInformation: (
  sessionUuid: string,
  surveyUuid: string,
  information: CompanyInformation
) => any = (
  sessionUuid: string,
  surveyUuid: string,
  information: CompanyInformation
) => {
  return (dispatch: any) => {
    let body = {};

    let answer: CompanyInformationAnswer = {
      type: "userdata",
      company: information,
    };

    const answers: CompanyInformationAnswer[] = [answer];

    if (sessionUuid !== "") {
      body = {
        answerSessionUuid: sessionUuid,
        answers: answers
      };
    } else {
      body = {
        surveyUuid: surveyUuid,
        answers: answers
      };
    }

    dispatch(surveyActions.saveCompanyInformationPending());
    return fetch(url + "survey/?token=" + token, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify(body)
    })
      .then(res => res.json())
      .then(res => {
        if (res.error) {
          throw res.error;
        }
        const sessionUuid =
          res.session !== undefined && res.session.uuid !== undefined
            ? res.session.uuid
            : "";
        dispatch(surveyActions.saveCompanyInformationSuccess(sessionUuid));
        return sessionUuid;
      })
      .catch(error => {
        dispatch(surveyActions.saveCompanyInformationError(error));
        throw error;
      });
  };
};
