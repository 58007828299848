import React, { FC } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Paper from "@material-ui/core/Paper";
import { useStyles } from "./Layout.style";
import { FormattedMessage, InjectedIntlProps, injectIntl } from "react-intl";
import { Button, LinearProgress, Grid, Box } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import ArrowLeftIcon from "@material-ui/icons/ArrowLeft";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import logoBSS from '../img/bss.png';

export interface LayoutProps extends InjectedIntlProps {
  children: JSX.Element[] | JSX.Element;
  hideFooter?: boolean;
  previousPath?: string;
  previousClickHandler?: (event: React.MouseEvent<HTMLElement>) => void;
  nextPath?: string;
  nextClickHandler?: (event: React.MouseEvent<HTMLElement>) => void;
  currentIndex?: number;
  totalItems?: number;
  loading?: boolean;
}

export const LayoutWrapped: FC<LayoutProps> = (props: LayoutProps) => {
  const classes = useStyles();
  const history = useHistory();

  const nextClickHandler = (event: React.MouseEvent<HTMLElement>) => {
    if (props.nextClickHandler !== undefined) {
      props.nextClickHandler(event);
    }
    if (props.nextPath !== undefined && props.nextPath !== "") {
      history.push(props.nextPath);
    }
  };
  const previousClickHandler = (event: React.MouseEvent<HTMLElement>) => {
    if (props.previousClickHandler !== undefined) {
      props.previousClickHandler(event);
    }
    if (props.previousPath !== undefined && props.previousPath !== "") {
      history.push(props.previousPath);
    }
  };
  return (
    <React.Fragment>
      <CssBaseline />
      <Box display="block" displayPrint="none">
        <AppBar position="absolute" color="primary" className={classes.appBar}>
          <Toolbar className={classes.toolbar}>
            <span className={classes.title}>
              <FormattedMessage id="product.title" />
            </span>
            <a href="https://bss.au.dk/" target="_blank" rel="noreferrer noopener"><img alt="BSS" src={logoBSS} className={classes.logo} title="Aarhus Universitet" /></a>
          </Toolbar>
        </AppBar>
      </Box>
      <main className={classes.layout}>
        <Paper className={classes.paper}>{props.children}</Paper>
      </main>

      
      {!props.hideFooter && (
        <Box display="block" displayPrint="none">
          <div className={classes.footer}>
            <Grid container spacing={5}>
              <Grid item xs={4}>
                <div className={classes.previousButton}>
                  <Button
                    color="primary"
                    variant="contained"
                    startIcon={<ArrowLeftIcon />}
                    onClick={previousClickHandler}
                  >
                    {props.intl.formatMessage({ id: "common.previous" })}
                  </Button>
                </div>
              </Grid>
              <Grid item xs>
                {props.currentIndex !== undefined &&
                  props.totalItems !== undefined && (
                    <div className={classes.progress}>
                      <LinearProgress
                        variant={props.loading ? "indeterminate" : "determinate"}
                        value={(props.currentIndex / props.totalItems) * 100}
                        className={classes.linearProgress}
                      />
                      {props.currentIndex}/{props.totalItems}
                    </div>
                  )}
              </Grid>
              <Grid item xs={4}>
                <div className={classes.nextButton}>
                  <Button
                    color="primary"
                    variant="contained"
                    endIcon={<ArrowRightIcon />}
                    onClick={nextClickHandler}
                  >
                    {props.intl.formatMessage({ id: "common.next" })}
                  </Button>
                </div>
              </Grid>
            </Grid>
          </div>
        </Box>
      )}
    </React.Fragment>
  );
};

export const Layout = injectIntl(LayoutWrapped);
