import React, { FC, useEffect } from "react";
import Typography from "@material-ui/core/Typography";
import { FormattedMessage, InjectedIntlProps, injectIntl } from "react-intl";
import { Layout } from "../layout/Layout";
import { useDispatch, useSelector } from "react-redux";
import { surveyActions } from "../actions/survey-actions";
import { paths } from "../router";
import { SurveyObject, CompanyInformation, IndustryObject } from "../customTypes";
import { RootState } from "../store";
import { useHistory } from "react-router-dom";
import { useStyles } from "./Industry.style";
import { Select } from "@material-ui/core";
import { MenuItem } from "@material-ui/core";
import { saveCompanyInformation } from "../api/api";

export interface IndustryProps extends InjectedIntlProps {}

export const IndustryWrapped: FC<IndustryProps> = (props: IndustryProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const styles = useStyles();
  
  const survey: SurveyObject = useSelector<RootState, SurveyObject>(
    state => state.surveyState.survey
  );
  const sessionUuid: string = useSelector<RootState, string>(
    state => state.surveyState.sessionUuid
  );
  const companyInformation: CompanyInformation = useSelector<
    RootState,
    CompanyInformation
  >(state => state.surveyState.companyInformation);

  useEffect(() => {
    // If no questions are found redirect to intro
    if (survey.questions === undefined) {
      history.push(paths.intro);
    } else {
      dispatch(surveyActions.setPage(paths.questionOutro));
      dispatch(surveyActions.setQuestionIndex(0));
    }
  }, []);

  const selectChanged = (
    e: React.ChangeEvent<{ name?: string | undefined; value: any }>
  ) => {
    const info: CompanyInformation = { 
        industryCode: e.target.value,
    }
    dispatch(surveyActions.setCompanyInformation(info));
  };

  if (survey.questions === undefined) {
    return <div></div>;
  }

  const routeToNext = () => {
    history.push(paths.question + "/1");
  };

  const nextClickHandler = () => {
    dispatch(
      saveCompanyInformation(sessionUuid, survey.uuid, companyInformation)
    )
      .then(() => {
        routeToNext();
      })
      .catch(() => {
        console.log("Error saving company information");
      });
  };

  return (
    <Layout
        previousPath={paths.intro}
        nextClickHandler={nextClickHandler}
    >
      <React.Fragment>
        <Typography component="h1" variant="h4" align="left" gutterBottom>
          <FormattedMessage id="industry.title" />
        </Typography>

        <div className={styles.informationBlock}>
          <FormattedMessage id="industry.question" />
          <br />
          <Select
            id="industry"
            defaultValue={companyInformation.industryCode || ''}
            onChange={selectChanged}
            placeholder={props.intl.formatMessage({
              id: "industry.industryPlaceholder"
            })}            
            fullWidth
          >
            <MenuItem value="0">
              <FormattedMessage id="industry.industryPlaceholder" />
            </MenuItem>
            {survey.industryCodes !== undefined &&
              survey.industryCodes.map((industry: IndustryObject, i: number) => {
                return (
                  <MenuItem value={industry.code} key={i}>{industry.name}</MenuItem>
                );
              })}
          </Select>
        </div>

      </React.Fragment>
    </Layout>
  );
};

export const Industry = injectIntl(IndustryWrapped);