import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
    li : {
        listStyleType: 'none'
    },
    list: {
        padding: 0,
    },
    labels: {
        padding: 0,
    },
    radarContainer: {
        height: 400,
        marginTop: 8,
    }
}));
