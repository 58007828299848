import React, { FC, useEffect, ReactElement, useState } from "react";
import Typography from "@material-ui/core/Typography";
import { FormattedMessage, InjectedIntlProps, injectIntl } from "react-intl";
import { Layout } from "../layout/Layout";
import { useDispatch, useSelector } from "react-redux";
import { surveyActions } from "../actions/survey-actions";
import { paths } from "../router";
import { SurveyObject, SelectedOptions, MatrixTitles } from "../customTypes";
import { RootState } from "../store";
import { useHistory } from "react-router-dom";
import { ChartValue, SemanticCategory } from "../reducers/chart-reducer";
import { chartActions } from "../actions/chart-actions";
import { getSemanticCategory, formatMultipleTexts, createLabelsWithIdentifiers, createEntries, labelIdentifiers, getMatrixChartX, getMatrixChartY, getMatrixMarkers } from "../util/chart-util";
import { useStyles } from "./SemanticResult.style";
import { addCompanyName } from "../util/util";
import { Box, Button } from "@material-ui/core";
import PrintIcon from '@material-ui/icons/Print';
import { RadarChart } from "../radar-chart/RadarChart";
import { Matrix } from "../matrix/Matrix";

export interface SemanticResultProps extends InjectedIntlProps {}

export const SemanticResultWrapped: FC<SemanticResultProps> = (
  props: SemanticResultProps
) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const styles = useStyles();
  const [showResults, setShowResults] = useState(false);

  const survey: SurveyObject = useSelector<RootState, SurveyObject>(
    state => state.surveyState.survey
  );
  const chartValues: ChartValue[] = useSelector<RootState, ChartValue[]>(
    state => state.chart.matrix
  );
  const semanticCategory: SemanticCategory = useSelector<RootState, SemanticCategory>(
    state => state.chart.semanticCategory
  );
  const selectedOptions: SelectedOptions = useSelector<RootState, SelectedOptions>(
    state => state.surveyState.selectedOptions
  );
  const companyName: string | undefined = useSelector<RootState, string | undefined>(
    state => state.surveyState.companyInformation.name
  );

  const values: number[] = useSelector<RootState, number[]>(
    state => state.chart.radar.map(item => item.value)
  );
  const averagesAll: number[] = useSelector<RootState, number[]>(
    state => state.chart.radar.map(item => item.averageAll)
  );
  const averagesIndustry: number[] = useSelector<RootState, number[]>(
    state => state.chart.radar.map(item => item.averageIndustry)
  );
  const labels: string[] = useSelector<RootState, string[]>(
    state => state.chart.radar.map(item => item.label)
  );

  const chartX = getMatrixChartX(chartValues);
  const chartY = getMatrixChartY(chartValues);
  const labelX: string = chartX !== undefined ? chartX.label : 'x';
  const labelY: string = chartY !== undefined ? chartY.label : 'y';

  const titles: MatrixTitles = {
    ownCompany: props.intl.formatMessage({ id: "results.yourCompany" }),
    averageAll: props.intl.formatMessage({ id: "results.averageAll" }),
    averageIndustry: props.intl.formatMessage({ id: "results.averageIndustry" }),
  }

  useEffect(() => {
    // If no questions are found redirect to intro
    if (survey.questions === undefined) {
      history.push(paths.intro);
    } else {
      dispatch(surveyActions.setPage(paths.semantic));
      dispatch(surveyActions.setQuestionIndex(0));
    }

    dispatch(chartActions.updateSemanticCategory(getSemanticCategory(chartValues)));
  }, []);

  const renderSemanticCategory = (semanticCategory: SemanticCategory): ReactElement => {
    let semanticFeedback: ReactElement = <div></div>;
    switch (semanticCategory) {
      case SemanticCategory.LEFT_LOWER:
        semanticFeedback = <div><FormattedMessage id="semantic.leftLower" /></div>;
        break;
      case SemanticCategory.LEFT_UPPER:
        semanticFeedback = <div><FormattedMessage id="semantic.leftUpper" /></div>;
        break;
      case SemanticCategory.RIGHT_LOWER:
        semanticFeedback = <div><FormattedMessage id="semantic.rightLower" /></div>;
        break;
      case SemanticCategory.RIGHT_UPPER:
        let notes: any[] = [];
        formatMultipleTexts(props.intl.formatMessage({ id: 'semantic.rightUpperNotes' }))
          .forEach((item, i) => notes.push(<p key={i}>{item}</p>));
        semanticFeedback = (
          <div>
            <p className={styles.main}>
              {props.intl.formatMessage({ id: 'semantic.rightUpper' })}
            </p>
            <hr/>
            {notes}
            </div>
        );
        break;
      default:
        semanticFeedback = <div></div>;
        break;
    }
    return semanticFeedback;
  };

  const renderFeedback = (selectedOptions: SelectedOptions): ReactElement => {
    const feedbackTexts: string[] = [];
    Object.values(selectedOptions).forEach(
      v => v
        .filter(s => s.feedback !== undefined)
        .forEach(f => feedbackTexts.push((f.feedback as string).trim()))
    );

    let feedbackText: string = feedbackTexts.join(' ');
    if (companyName !== undefined) {
      feedbackText = addCompanyName(feedbackText, companyName)
    }

    return (
      <div
        className={styles.feedback}
        dangerouslySetInnerHTML={{
        __html:
        feedbackText
      }}
    />)
  };

  if (survey.questions === undefined) {
    return <div></div>;
  }

  const toggleResults: string = showResults ? props.intl.formatMessage({ id: 'semantic.hideResults' }) : props.intl.formatMessage({ id: 'semantic.showResults' })

  return (
    <Layout
      previousPath={paths.companyForm}
      nextPath={paths.outro}
    >
      <React.Fragment>
        <Typography component="h1" variant="h4" align="left" gutterBottom>
          <FormattedMessage id="semantic.title" />
        </Typography>
        <div>
          {renderSemanticCategory(semanticCategory)}
          {renderFeedback(selectedOptions)}

          <Box display="block" displayPrint="none">
            <Button className={styles.resultsButton} onClick={() => setShowResults(!showResults)}>{toggleResults}</Button>
          </Box>

          {showResults &&
            <div>
              <div className={styles.pageBreakBefore}></div>
              <div className={styles.chartContainer}>
                <RadarChart
                  labels={createLabelsWithIdentifiers(labels, labelIdentifiers)}
                  entries={createEntries(props, values, averagesAll, averagesIndustry)}
                />            
              </div>
          
              <div className={styles.pageBreakBefore}></div>
              <Matrix 
                labelX={labelX} 
                labelY={labelY}
                markers={getMatrixMarkers(chartX, chartY, titles)}
              />
            </div>
          }

        </div>
        <div className={styles.printIconContainer}>
          <Box display="block" displayPrint="none">
            <Button
              onClick={() => window.print()}
              variant="contained"
              color="primary"
              size="small"
              startIcon={<PrintIcon />}>
                {props.intl.formatMessage({ id: 'semantic.print' })}
            </Button>
          </Box>
        </div>
      </React.Fragment>
    </Layout>
  );
};

export const SemanticResult = injectIntl(SemanticResultWrapped);
