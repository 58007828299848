import { ChartAction } from "../actions/chart-actions";
import { calcRadarValue, calcMatrixValue } from "../util/chart-util";

export function chartReducer(state: ChartState = chartStateInitial, action: ChartAction) {
    switch (action.type) {
        case 'FETCH_STATISTICS_PENDING':
            return {
                ...state,
                pending: true,
            };
        case 'FETCH_STATISTICS_SUCCESS':
            return {
                ...state,
                pending: false,
                radar: action.payload.spider.map((item: any) => { 
                    return {
                        uuid: item.uuid,
                        label: item.name,
                        value: calcRadarValue(item.score, item.max),
                        averageAll: calcRadarValue(item.average.all, item.max),
                        averageIndustry: calcRadarValue(item.average.industry, item.max),
                    }
                }),
                matrix: action.payload.matrix.map((item: any) => {
                    return {
                        uuid: item.uuid,
                        label: item.name,
                        value: calcMatrixValue(item.score, item.max),
                        averageAll: calcMatrixValue(item.average.all, item.max),
                        averageIndustry: calcMatrixValue(item.average.industry, item.max),
                    }
                })
            };
        case 'FETCH_STATISTICS_ERROR':
            return {
                ...state,
                pending: false,
                error: action.payload
            };
        case 'UPDATE_DIALOG':
            return {
                ...state,
                dialog: action.payload
            };
        case 'UPDATE_SEMANTIC_CATEGORY':
            return {
                ...state,
                semanticCategory: action.payload
            };
        default:
            return state;
    }
}

export enum SemanticCategory {
    LEFT_LOWER = "LEFT_LOWER",
    LEFT_UPPER = "LEFT_UPPER",
    RIGHT_LOWER = "RIGHT_LOWER",
    RIGHT_UPPER = "RIGHT_UPPER"
};

export const chartStateInitial: ChartState = {
    pending: false,
    radar: [
        {
            uuid: '',
            label: '-', 
            value: 0, 
            averageAll: 0,
            averageIndustry: 0,
        },
        {
            uuid: '',
            label: '-', 
            value: 0, 
            averageAll: 0,
            averageIndustry: 0,
        },
        {
            uuid: '',
            label: '-', 
            value: 0, 
            averageAll: 0,
            averageIndustry: 0,
        },
        {
            uuid: '',
            label: '-', 
            value: 0, 
            averageAll: 0,
            averageIndustry: 0,
        },
        {
            uuid: '',
            label: '-', 
            value: 0, 
            averageAll: 0,
            averageIndustry: 0,
        }
    ],
    matrix: [
        {
            uuid: '',
            label: '-', 
            value: 0, 
            averageAll: 0,
            averageIndustry: 0,
        },
        {
            uuid: '',
            label: '-', 
            value: 0, 
            averageAll: 0,
            averageIndustry: 0,
        },
    ],
    dialog: {
        title: '-',
        content: '-',
    },
    semanticCategory: SemanticCategory.LEFT_LOWER
}

export interface ChartState {
    pending: boolean;
    radar: ChartValue[];
    matrix: ChartValue[];
    dialog: ChartDialog,
    semanticCategory: SemanticCategory
}

export interface ChartDialog {
    title: string;
    content: string;
}

export interface ChartValue {
    uuid: string;
    label: string;
    value: number;
    averageAll: number;
    averageIndustry: number;
}